<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <div v-if="selectedCurrency && selectedBaseCurrency" class="d-flex flex-row align-items-center">
        <p class="font-weight-bold" style="font-size: 1em;">{{selectedCurrency.code}}</p>
        <p class="mx-1"> / </p>
        <p class="font-weight-bold" style="font-size: 0.8em;">{{selectedBaseCurrency.code}}</p>
      </div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <!--begin::Form Wizard-->
        <form class="form" @submit.prevent="handleSubmit(_handleSubmit)">
          <!--begin::Step 1-->
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('service.currency') }}</label>
                  <validation-provider
                    rules="required" :name="$t('service.currency')" 
                    v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedCurrency"
                      :options="getFilteredCurrencies"
                      :allow-empty="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      open-direction="bottom"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='code'
                      label='code'
                      :placeholder="$t('service.currency')"
                      :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option.code | uppercase }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option.code | uppercase }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('service.base_currency') }}</label>
                  <validation-provider
                    rules="required" :name="$t('service.base_currency')" 
                    v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedBaseCurrency"
                      :options="currencies"
                      :allow-empty="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      open-direction="bottom"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='code'
                      label='code'
                      :placeholder="$t('service.base_currency')"
                      :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option.code | uppercase }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option.code | uppercase }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{
                    $t("service.trade.price")
                  }}</label>
                  <validation-provider
                    :rules="{required: true}"
                    :name="$t('service.trade.price')"
                    v-slot="{ classes, errors }"
                    class="col-lg-9 col-xl-9"
                    v-if="!isLoading"
                  >
                    <currency-input
                      class="form-control"
                      autocomplete="off"
                      :precision="amountPrecision"
                      :distractionFree="false"
                      v-model="formData.price"
                      placeholder="0.00000000"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{
                    $t("service.trade.spot_status")
                  }}</label>
                  <div class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <div class="radio-inline col-form-label">
                      <label class="radio" for="spot_status_active">
                        <input
                          type="radio"
                          id="spot_status_active"
                          :checked="formData.spot_status === '1'"
                          @input="onChangeSpotStatus('1')"
                        />
                        <span></span>{{ $t('service.active') }}
                      </label>
                      <label class="radio" for="spot_status_passive">
                        <input
                          type="radio"
                          id="spot_status_passive"
                          :checked="formData.spot_status === '0'"
                          @input="onChangeSpotStatus('0')"
                        />
                        <span></span>{{ $t('service.passive') }}
                      </label>
                    </div>
                  </div>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <!-- <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{
                    $t("service.trade.margin_status")
                  }}</label>
                  <div class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <div class="radio-inline col-form-label">
                      <label class="radio" for="margin_status_active">
                        <input
                          type="radio"
                          id="margin_status_active"
                          :checked="formData.margin_status === '1'"
                          @input="onChangeMarginStatus('1')"
                        />
                        <span></span>{{ $t('service.active') }}
                      </label>
                      <label class="radio" for="margin_status_passive">
                        <input
                          type="radio"
                          id="margin_status_passive"
                          :checked="formData.margin_status === '0'"
                          @input="onChangeMarginStatus('0')"
                        />
                        <span></span>{{ $t('service.passive') }}
                      </label>
                    </div>
                  </div>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div> -->

              </div>
            </div>
          </div>
          <!--begin::Actions-->
          <div class="d-flex justify-content-end border-top mt-5 pt-10">
            <button
              type="submit"
              class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
              :disabled="isSubmitting"
            >
              {{ $t('commons.submit') }}
            </button>
          </div>
          <!--end::Actions-->
        </form>
        <!--end::Form Wizard-->
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Skeleton } from 'vue-loading-skeleton';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: "NewSpotTradeTP",
  data() {
    return {
      formData: {
        base_currency_id: "",
        currency_id: "",
        price: null,
        spot_status: '0',
        margin_status: '0',
      },
      selectedBaseCurrency: null,
      selectedCurrency: null
    };
  },
  components: {
    Skeleton,
    Multiselect
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.spottradeManagement.isLoading,
      isSubmitting: (state) => state.spottradeManagement.isSubmitting,
      currencies: (state) => state.spottradeManagement.currencies
    }),
    getFilteredCurrencies() {
      return this.currencies.map(val => ({ ...val, $isDisabled: val.id === this.selectedBaseCurrency?.id }));
    },
    amountPrecision() {
      return this.selectedBaseCurrency?.decimal || 8;
    },
  },
  methods: {
    ...mapActions('spottradeManagement', ['GET_STORE_DATA', 'STORE']),
    _handleSubmit() {
      this.formData.base_currency_id = this.selectedBaseCurrency?.id;
      this.formData.currency_id = this.selectedCurrency?.id;
      console.log(this.formData);

      this.STORE(this.formData);
    },
    onChangeSpotStatus(status) {
      this.formData.spot_status = status;
    },
    onChangeMarginStatus(status) {
      this.formData.margin_status = status;
    },
  },
  created() {
    this.GET_STORE_DATA();
  },
};
</script>
