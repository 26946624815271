<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data'/>
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container-fluid">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('spottrade_management.add_new') }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <NewSpotTradeTP />
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
  </div>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import NewSpotTradeTP from '@/components/management/NewSpotTradeTP.vue';

export default {
  name: 'NewSpotTradeTransactionPair',
  data() {
    return { }
  },
  components: {
    Breadcrumb,
    NewSpotTradeTP
  },
  computed: {
    breadcrumb_data() {
      return {
        title:  this.$t('general_management.title'),
        subtitle: [ 
          { title: this.$t('general_management.spot_trade_tp_title'), route: '/spot-trade-transaction-pairs-management' },
          this.$t("spottrade_management.add_new")
        ],
      }
    }
  }
}
</script>
